<template>
  <div class="headerStyle">
    <div class="logo">NetChat</div>
    <div class="userInfo">
      <div class="desc" v-show="$userInfo">
        欢迎您! {{userInfo ? (userInfo.username || userInfo.account) : ''}}
      </div>
      <div class="avatar" v-show="userInfo">
        <el-dropdown trigger="click" @command="handleCommand">
          <img :src="userInfo ? backImgUrl(userInfo.avatar) : ''" alt="avatar">
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personal" style="text-align: center;">个人信息</el-dropdown-item>
            <el-dropdown-item command="logout" style="text-align: center">登出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-show="!userInfo" style="margin-right: 20px">请登录!</div>
    </div>
  </div>
</template>
<script>
  import getAndBackImgUrl from "../common/getAndBackImgUrl";
  import Cookies from 'js-cookie';
  export default {
    name: "headerStyle",
    data() {
      return {
        userInfo:null
      }
    },
    mixins:[getAndBackImgUrl],
    created() {
      this.$bus.$on('updateUserInfo',this.updateUserInfo);
      //初始化
      this.initInfo();
    },
    beforeDestroy(){
      this.$bus.$off('updateUserInfo',this.updateUserInfo);
    },
    methods: {
      //初始化
      initInfo(){
        this.userInfo = this.$userInfo;
        if (this.userInfo){
          this.getImgInfo([this.userInfo.avatar]);
        }
      },
      //更新用户信息
      updateUserInfo(){
        this.userInfo = this.$userInfo;
        if (this.userInfo){
          this.getImgInfo([this.userInfo.avatar]);
        }
      },
      //处理菜单的回调
      handleCommand(val){
        if (val === 'personal'){
          this.$router.push({
            name:"个人设置"
          })
        } else if (val === 'logout'){
          this.userInfo = this.$userInfo = null;
          Cookies.remove('user_login_token');
          Cookies.remove('userName');
          this.$router.push({
            name:"登录"
          })
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .headerStyle {
    width: 100%;
    height: 50px;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      padding: 0 20px;
      cursor: pointer;
      user-select: none;
    }

    .userInfo {
      display: flex;
      align-items: center;

      .desc {
        font-weight: bold;
        margin-right: 10px;
      }

      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border: 1px solid #666;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
</style>
