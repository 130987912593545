import service from "@/utils/index";

//表格的查询功能
export const getTableInfo = (tableName,data) => {
  return service({
    url:`/${tableName}/queryByCond`,
    method:"post",
    data
  })
}
//表格的保存功能
export const saveTableInfo = (tableName,data) => {
  return service({
    url:`/${tableName}/save`,
    method:"post",
    data
  })
}
//表格的删除功能
export const deleteTableInfo = (tableName,id) => {
  return service({
    url:`/${tableName}/delete/${id}`,
    method:"get"
  })
}

//发送邮箱验证码
export const validateMail = data => {
  return service({
    url:`/sendMailCode`,
    method:"post",
    data
  })
}

//注册
export const registerInfo = data => {
  return service({
    url:`/register`,
    method:"post",
    data
  })
}

//注册
export const userLogin = data => {
  return service({
    url:`/login`,
    method:"post",
    data
  })
}

//查询用户信息
export const getUserInfo = data => {
  return service({
    url:"/getUserInfo",
    method:"post",
    data
  })
}

//查询qiniu token
export const getQiniuToken = () => {
  return service({
    url:"/getQiniuToken",
    method:"get"
  })
}


//上传文件至七牛云
export const getImgUrl = key => {
  return service({
    url:"/getImgUrl",
    method:"post",
    data:{
      key
    }
  })
}

