<template>
  <div class="footer">
    <div class="options">
      <div>建议反馈</div>
      <div>联系我们</div>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023017705号</a>
      </div>
      <div>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402014023" target="_blank">
          <img src="../../assets/beian.png" alt="备案图标" style="margin-right: 3px">
          <span>京公网安备 11011402014023号</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name:"",
    data(){
      return{

      }
    },
    created(){

    },
    methods:{

    },
  }
</script>
<style lang="less" scoped>
  .footer{
    width: 100%;
    height: 40px;
    background: #ccc;
    .options{
      width: 1000px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      div{
        font-size: 12px;
        color: #fff;
        margin: 0 15px;
        cursor: pointer;
        user-select: none;
        a{
          color: #fff;
          display: flex;
          align-items: center;
        }
      }
    }
  }
</style>
