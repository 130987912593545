import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import "./style/style.css";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import moment from 'moment';
Vue.prototype.$moment = moment;
import { v4 as uuidv4 } from 'uuid';
Vue.prototype.$uuidv4 = uuidv4;
import {getUserData} from "./utils/getUserInfo";
Vue.prototype.$userInfo = null;
Vue.prototype.$getUserData = getUserData;
getUserData(Vue);
Vue.prototype.$qiniuDomain = 'http://rxh1ijyre.hb-bkt.clouddn.com/';

Vue.config.productionTip = false

new Vue({
  beforeCreate(){
    Vue.prototype.$bus = this
  },
  router,
  render: h => h(App),
}).$mount('#app')
