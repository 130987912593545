import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  routes:[
    {
      name:"网站首页",
      path:"/",
      redirect:"/home"
    },
    {
      name:"用户操作",
      path:"/user",
      redirect:"/user/login",
      component:()=>import("@/views/login"),
      children:[
        {
          name:"登录",
          path:"login",
          component:()=>import("@/views/login/login.vue"),
        },
        {
          name:"注册",
          path:"register",
          component:()=>import("@/views/login/register.vue"),
        }
      ]
    },
    {
      name:"主页",
      path:"/home",
      redirect:"/home/chat",
      component:()=>import("@/views/mainContainer"),
    },
    {
      name:"聊天列表",
      path:"/home/chat",
      component:()=>import("@/views/mainContainer"),
      children:[
        {
          name:"会话详情",
          path:"detail",
          component:()=>import("@/views/mainContainer/chatDetail"),
        },
        {
          name:"发起群聊",
          path:"initiateGroupChat",
          component:()=>import("@/views/mainContainer/initiateGroupChat"),
        },
        {
          name:"个人设置",
          path:"personal",
          component:()=>import("@/views/personal"),
        },
      ]
    },
    {
      name:"联系人列表",
      path:"/home/contactsList",
      component:()=>import("@/views/mainContainer"),
      children:[
        {
          name:"联系人详情",
          path:"detail",
          component:()=>import("@/views/mainContainer/contactDetail"),
        },
        {
          name:"新联系人",
          path:"newContacts",
          component:()=>import("@/views/mainContainer/newContacts"),
        }
      ]
    }
  ],
  mode:"history"
})
