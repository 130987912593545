import Cookies from "js-cookie";
import $ from "jquery";

export const getUserData = Vue => {
  let account = Cookies.get('userName');
  let params = {account};
  if (!Cookies.get('user_login_token')){
    if (location.href.includes('user/login') || location.href.includes('user/register')){
      return
    }else {
      window.location.assign('/user/login');
      return;
    }
  }

  $.ajax({
    url: "/api/getUserInfo", //请求地址
    type: 'post',   //请求方式
    data: params, //请求参数
    async: false,   //是否异步
    success: function (res) {
      if (res.status === 200) {
        Vue.prototype.$userInfo = res.data;


        // Create WebSocket connection.
        const socket = new WebSocket('wss://'+location.host+':8081');
        // const socket = new WebSocket('ws://localhost:8080');
        Vue.prototype.$socket = socket;

        // Connection opened
        socket.addEventListener('open', function (event) {
          let data = {
            type: "connect",
            id: res.data.id
          }
          socket.send(JSON.stringify(data));
        });
      } else {
        Vue.prototype.$alert(res.message, '提示', {
          confirmButtonText: '确定',
          callback: action => {
            Cookies.remove('userName');
            Cookies.remove('user_login_token');
            window.location.href = "/user/login";
          }
        });
      }
    },
    error: function (status) {
      Vue.prototype.$alert('请重新登陆', '错误', {
        confirmButtonText: '确定',
        callback: action => {
          Cookies.remove('userName');
          Cookies.remove('user_login_token');
          window.location.href = "/user/login"
        }
      });
    }

  });
}
