<template>
  <div id="app">
    <HeaderCom></HeaderCom>
    <router-view></router-view>
    <FooterCom></FooterCom>
  </div>
</template>

<script>
import HeaderCom from "./views/header";
import FooterCom from "./views/footer";
import Cookies from 'js-cookie';
import {getQiniuToken, getUserInfo} from "./api";
export default {
  name: 'App',
  components:{
    HeaderCom,
    FooterCom
  },
  created() {
    this.addSocketMsg();
    this.$bus.$on('addSocket',this.addSocketMsg);
  },
  beforeDestroy(){
    this.$bus.$off('addSocket',this.addSocketMsg);
  },
  methods:{
    //获取qiniu云token
    getTokenForQiniu(){
      getQiniuToken().then(res=>{
        if (res.status === 200){
          this.__proto__.__proto__.$qiniuToken = res.data;
        }
      })
    },
    //添加socket接受信息事件
    addSocketMsg(){
      if (this.$socket){
        // Listen for messages
        this.$socket.addEventListener('message', event => {
          let data = JSON.parse(event.data);
          if (data.type === 'userVerify' || data.type === 'userVerifyResult'){
            this.$bus.$emit('updateVerifyInfo',JSON.parse(event.data));
          } else if (data.type === 'passVerify'){
            this.$bus.$emit('updateVerifyInfo',JSON.parse(event.data));
          } else if (data.type === 'generateGroupChat'){
            this.$bus.$emit('updateGroupChatList',JSON.parse(event.data));
          } else {
            this.$bus.$emit('updateChat',JSON.parse(event.data));
            this.$bus.$emit('updateChatList',JSON.parse(event.data));
          }
        });
        //获取qiniutoken
        this.getTokenForQiniu();
      } else if (this.$userInfo && this.$userInfo.id) {
        this.addSocketMsg();
      }
    }
  }
}
</script>

<style lang="less" scoped>
  #app{
    overflow: hidden;
  }
</style>
