// mixin.js

import {getImgUrl} from "../../api";

export default {
  data() {
    return {
      imgKeysUrlData:[]
    }
  },
  methods: {
    //返回图片的url
    backImgUrl(key){
      let currentData = this.imgKeysUrlData.filter(item=>item.key === key)[0];
      if (currentData){
        return currentData.url;
      } else {
        return "";
      }
    },
    //请求图片的url
    getImgInfo(key){
      getImgUrl(key).then(res=>{
        if (res.status === 200){
          this.imgKeysUrlData = [...res.data,...this.imgKeysUrlData];
        } else {
          this.imgKeysUrlData = [];
        }
      }).catch(err=>{
        this.imgKeysUrlData = [];
      })
    },
  }
}
